.frame5-frame5 {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  justify-content:center;
  box-sizing: border-box;
  min-height: 100%;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 40px;
  background-image: linear-gradient(180deg, rgba(105, 103, 222, 1) 0%, rgba(130, 110, 246, 1) 100%);
}
.frame5-frame {
 
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 40px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.frame5-group1948754297 {
  top: 7vh;
  left: 26px;
  width: 22px;
  height: 26px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.frame5-vector27 {
  top: 6.000068187713623px;
  left: 0.9999906420707703px;
  width: 20px;
  height: 20px;
  position: absolute;
  box-sizing: border-box;
}
.frame5-vector28 {
  top: 10.00005054473877px;
  left: 10.99997615814209px;
  width: 4px;
  height: 8px;
  position: absolute;
  box-sizing: border-box;
}
.frame5-ellipse274 {
  top: 0.0000845299509819597px;
  left: 8.000004768371582px;
  width: 6px;
  height: 3px;
  position: absolute;
  box-sizing: border-box;
}
.frame5-vector388 {
  top: 4.000066757202148px;
  left: 18.999980926513672px;
  width: 3px;
  height: 3px;
  position: absolute;
  box-sizing: border-box;
}
.frame5-vector389 {
  top: 4.000066757202148px;
  left: 2.999971866607666px;
  width: 3px;
  height: 3px;
  position: absolute;
  box-sizing: border-box;
}
.frame5-text {
  margin-top:11px;
  left: 25px;
  right: 25px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: relative;
  font-size: 2.5vh;
  font-style: Bold;
  text-align: left;
  font-family: Geometria;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  text-decoration: none;
}
.frame5-text02 {
    margin-top:40px;
    left: 25px;
    color: rgba(255, 218, 145, 1);
    height: auto;
    position: relative;
    font-size: 4vh;
    font-style: ExtraBold;
    text-align: left;
    font-family: Geometria;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}
.frame5-text03 {
  color: rgba(255, 255, 255, 1);
}
.answCont {
    position: relative;
    top: 22vh;
    text-align: left;
    width:100vw;

}
.frame5-frame50085 {
    left: 50%;
    transform: translate(-50%);
    margin-top: 30px;
    width: 320px;
    height: 8vh;
    display: flex;
    padding: 0 17px;
    position: relative;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 60px;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
}
.frame5-frame50084 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    justify-content: center;
}
.frame5-frame1 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 20px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.frame5-rectangle4192 {
  top: -4px;
  left: -4px;
  width: 32px;
  height: 32px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: 0;
}
.frame5-text05 {
    color: rgba(105, 103, 222, 1);
    width: 210px;
    height: auto;
    font-size: 2.8vw;
    align-self: auto;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}
.frame5-frame50086 {
    left: 50%;
    transform: translate(-50%);
    margin-top: 10px;
    width: 320px;
    height: 8vh;
    display: flex;
    padding: 0 17px;
    position: relative;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 60px;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
}
.frame5-frame500841 {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  justify-content: center;
}
.frame5-frame11 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 20px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.frame5-rectangle41921 {
  top: -4px;
  left: -4px;
  width: 32px;
  height: 32px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: 0;
}
.frame5-vector1 {
    position: absolute;
    width: 23.5px;
    height: 16px;
    left: 2px;
    top: 5.5px;
}
.frame5-text07 {
  color: rgba(105, 103, 222, 1);
  width: 210px;
  height: auto;
  font-size: 2.8vw;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Geometria;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-frame50087 {
    left: 50%;
    transform: translate(-50%);
    margin-top: 10px;
    width: 320px;
    height: 8vh;
    display: flex;
    padding: 0 17px;
    position: relative;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 60px;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
}
.frame5-frame500842 {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  justify-content: center;
}
.frame5-frame12 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 20px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.frame5-rectangle41922 {
  top: -4px;
  left: -4px;
  width: 32px;
  height: 32px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: 0;
}
.frame5-text09 {
  color: rgba(105, 103, 222, 1);
  width: 210px;
  height: auto;
  font-size: 2.8vw;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Geometria;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-frame50088 {
    left: 50%;
    transform: translate(-50%);
    margin-top: 10px;
    width: 320px;
    height: 8vh;
    display: flex;
    padding: 0 17px;
    position: relative;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 60px;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
}
.frame5-frame500843 {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-color: transparent;
  margin-right: 0;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
  justify-content: center;
}
.frame5-frame13 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: transparent;
  margin-right: 20px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0;
}
.frame5-rectangle41923 {
  top: -4px;
  left: -4px;
  width: 32px;
  height: 32px;
  position: absolute;
  box-sizing: border-box;
  border-color: transparent;
  margin-right: 0;
  border-radius: 50px;
  margin-bottom: 0;
}
.frame5-text11 {
  color: rgba(105, 103, 222, 1);
  width: 210px;
  height: auto;
  font-size: 2.8vw;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Geometria;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-text13 {
  top: 15vh;
  left: 23px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 8vh;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Geometria;
  font-weight: 700;
  line-height: 100%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-text15 {
  top: 8vh;
  left: 56px;
  color: rgba(255, 218, 145, 1);
  height: auto;
  position: absolute;
  font-size: 1.5vh;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Geometria;
  font-weight: 700;
  line-height: 100%;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
.frame5-vector29 {
  top: 88px;
  left: 233px;
  width: 30px;
  height: 30px;
  position: absolute;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
.frame5-vector30 {
  top: 135.32110595703125px;
  left: 285.10064697265625px;
  width: 21px;
  height: 20px;
  position: absolute;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 0;
}
