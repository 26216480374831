.frame1024 {
    width: 100%;
    height: 927px;
    display: flex;
    padding: 20px;
    overflow: hidden;
    position: relative;
    max-width: 466px;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 0px 0px 0px 0px;
    flex-direction: column;
    background-color: var(--dl-color-default-gamebg);
    background-image: linear-gradient(0deg, rgba(255, 111, 49, 1) 0%, rgba(255, 50, 50, 1) 76%, rgba(255, 111, 28, 1) 100%);
}

.frame47915 {
    display: flex;
    padding: 30px 20px;
    position: relative;
    align-self: stretch;
    box-sizing: content-box;
    align-items: center;
    border-color: rgba(255, 255, 255, 0.5);
    border-style: solid;
    border-width: 10px;
    margin-right: 0;
    border-radius: 30px;
    margin-bottom: 0;
    flex-direction: column;
    background-clip: padding-box;
    background-color: var(--dl-color-gray-white);
}

.frame1948754319 {
    display: flex;
    z-index: 1;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 50px;
    flex-direction: column;
}

.text {
    height: auto;
    font-size: 30px;
    align-self: stretch;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 30px;
    text-decoration: none;
}

.frame1948754317 {
    display: flex;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
}

.text02 {
    color: red;
    height: auto;
    font-size: 17px;
    align-self: stretch;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: 120.00000476837158%;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 30px;
    text-decoration: none;
}

.frame1948754311 {
    display: flex;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 30px;
}

.frame1948754301 {
    width: 25px;
    height: 25px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 10px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    justify-content: center;
}

.ellipse501 {
    width: 11px;
    height: 11px;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
}

.frame1948754309 {
    width:0.5vw;
    display: flex;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
    flex-wrap:wrap;
}

.text04 {
    height: auto;
    font-size: 14px;
    align-self: stretch;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0px;
    text-decoration: none;
}

.text06 {
    height: auto;
    font-size: 12px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Geometria;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame19487543171 {
    display: flex;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 30px;
}

.frame19487543011 {
    width: 25px;
    height: 25px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 10px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    justify-content: center;
}

.ellipse5011 {
    width: 11px;
    height: 11px;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
}

.frame19487543091 {
    width: 0.5vw;
    display: flex;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
}

.text08 {
    height: auto;
    font-size: 14px;
    align-self: stretch;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0px;
    text-decoration: none;
}

.text10 {
    height: auto;
    font-size: 12px;
    align-self: stretch;
    font-style: Regular;
    text-align: left;
    font-family: Geometria;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame1948754318 {
    display: flex;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 30px;
}

.frame19487543012 {
    width: 25px;
    height: 25px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 10px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    justify-content: center;
}

.ellipse5012 {
    width: 11px;
    height: 11px;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
}

.frame19487543092 {
    width:0.5vw;
    display: flex;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
}

.text12 {
    height: auto;
    font-size: 14px;
    align-self: stretch;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame1948754316 {
    display: flex;
    position: relative;
    align-self: stretch;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
}

.frame1948754321 {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.group1948754302 {
    width: 22px;
    height: 22px;
    position: relative;
    box-sizing: border-box;
    margin-right: 15px;
    margin-bottom: 0;
}

.text14 {
    flex-wrap:wrap;
    height: auto;
    flex-grow: 1;
    font-size: 12px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Geometria;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame1948754322 {
    display: flex;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
}

.text16 {
    height: auto;
    font-size: 23px;
    align-self: stretch;
    font-style: Bold;
    text-align: left;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 30px;
    text-decoration: none;
}

.frame1948754320 {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 30px;
}

.group1948754301 {
    width: 22px;
    height: 22px;
    position: relative;
    box-sizing: border-box;
    margin-right: 15px;
    margin-bottom: 0;
}

.text18 {
    flex-wrap:wrap;
    height: auto;
    flex-grow: 1;
    font-size: 12px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Geometria;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.frame19487543211 {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.group19487543021 {
    width: 22px;
    height: 22px;
    position: relative;
    box-sizing: border-box;
    margin-right: 15px;
    margin-bottom: 0;
}

.text23 {
    flex-wrap:wrap;
    height: auto;
    flex-grow: 1;
    font-size: 12px;
    align-self: auto;
    font-style: Regular;
    text-align: left;
    font-family: Geometria;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}
