.i-phone-s-e1-container {
    z-index: 100;
    min-height: 100vh;
}

.i-phone-s-e1-i-phone-s-e1 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    padding: 10px 20px;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    background-color: var(--dl-color-default-main);
    background-image: linear-gradient(0deg, #FF6F32 0%, #FF3333 78.14%, #FF6F1D 102.34%);
}

.i-phone-s-e1-frame1948754382 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 1;
    border-color: transparent;
    flex-direction: column;
}

.i-phone-s-e1-frame1948754376 {
    margin: 0 0 10px 0;
    display: flex;
    padding: 1px 0 1px 0;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
}

.i-phone-s-e1-group47895 {
    width: 50px;
    height: 50px;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-frame1948754380 {
    display: flex;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-radius: 14px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.699999988079071);
}

.i-phone-s-e1-frame1948754399 {
    margin: 0 0 0px 0;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    flex-direction: column;
}

.i-phone-s-e1-botbtn {
    width: 260px;
    margin: 0 0 10px 0;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: rgba(236, 14, 0, 1);
}

.i-phone-s-e1-helpcircle {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: white
}

.i-phone-s-e1-botbtn1 {
    width: 260px;
    margin: 0 0 10px 0;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: rgba(236, 14, 0, 1);
}

.i-phone-s-e1-helpcircle1 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text1 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: white
}

.i-phone-s-e1-botbtn2 {
    width: 260px;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: rgba(236, 14, 0, 1);
}

.i-phone-s-e1-helpcircle2 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text2 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: white
}

.i-phone-s-e1-frame1948754400 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    flex-direction: column;
}

.i-phone-s-e1-botbtn3 {
    width: 260px;
    margin: 0 0 10px 0;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: white
}

.i-phone-s-e1-helpcircle3 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text3 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: black
}

.i-phone-s-e1-botbtn4 {
    width: 260px;
    margin: 0 0 10px 0;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: white
}

.i-phone-s-e1-helpcircle4 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text4 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: black
}

.i-phone-s-e1-botbtn5 {
    width: 260px;
    margin: 0 0 10px 0;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: white
}

.i-phone-s-e1-helpcircle5 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text5 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: black
}

.i-phone-s-e1-botbtn6 {
    width: 260px;
    margin: 0 0 10px 0;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: white
}

.i-phone-s-e1-helpcircle6 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text6 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: black
}

.i-phone-s-e1-botbtn7 {
    width: 260px;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: white
}

.i-phone-s-e1-helpcircle7 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text7 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: black
}

.i-phone-s-e1-botbtn8 {
    width: 260px;
    display: flex;
    padding: 10px 15px;
    position: relative;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15000000596046448);
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    border-radius: 5px;
    background-color: white
}

.i-phone-s-e1-helpcircle8 {
    width: 24px;
    height: 24px;
    margin: 0 20px 0 0;
    position: relative;
    box-sizing: border-box;
    object-fit: cover;
    border-color: transparent;
}

.i-phone-s-e1-text8 {
    height: auto;
    font-size: 2vh;
    align-self: auto;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    color: black
}
