.ellipse-icon,
.frame-icon {
    position: absolute;
    top: 300px;
    left: 38px;
    width: 300px;
    height: 268px;
}

.frame-icon {
    height: 109.98%;
    width: 329.6%;
    top: 0;
    right: -229.6%;
    bottom: -9.98%;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.div1 {
    position: absolute;
    height: 10.59%;
    width: 70.4%;
    top: 19.83%;
    right: 17.33%;
    bottom: 69.58%;
    left: 17.33%;
    letter-spacing: 0.24px;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.frame-icon1 {
    position: absolute;
    margin: auto;
    left: 53%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 192px;
    height: 193px;
    overflow: hidden;
}

.vector-icon,
.vector-icon1,
.vector-icon2 {
    position: absolute;
    height: 1.74%;
    width: 3.91%;
    top: 86.45%;
    right: 54.49%;
    bottom: 11.8%;
    left: 41.6%;
    max-width: 100%;
    max-height: 100%;
}

.vector-icon1,
.vector-icon2 {
    height: 2.53%;
    width: 5.45%;
    top: 14.17%;
    right: 31.81%;
    bottom: 83.3%;
    left: 62.74%;
}

.vector-icon2 {
    height: 3.68%;
    width: 8.05%;
    top: 12.19%;
    right: 50.08%;
    bottom: 84.12%;
    left: 41.87%;
}

.div2 {
    position: absolute;
    height: 9.24%;
    width: 73.07%;
    top: 72.54%;
    right: 14.13%;
    bottom: 18.23%;
    left: 14.13%;
    font-size: 20px;
    letter-spacing: 0.24px;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.vector-icon3,
.vector-icon4,
.vector-icon5 {
    position: absolute;
    height: 1.34%;
    width: 4.12%;
    top: 84.4%;
    right: 23.83%;
    bottom: 14.26%;
    left: 72.05%;
    max-width: 100%;
    max-height: 100%;
}

.vector-icon4,
.vector-icon5 {
    height: 2.32%;
    width: 5.12%;
    top: 91.59%;
    right: 26.36%;
    bottom: 6.09%;
    left: 68.53%;
}

.vector-icon5 {
    height: 1.81%;
    width: 3.53%;
    top: 70.69%;
    right: 9%;
    bottom: 27.5%;
    left: 87.47%;
}

.div {
    position: relative;
    border-radius: 40px;
    background: linear-gradient(180deg, #6967de, #836ef7);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-family: Geometria;
}
