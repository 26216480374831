.frame-icon {
    position: absolute;
    height: 109.98%;
    width: 329.6%;
    top: 0;
    right: -229.6%;
    bottom: -9.98%;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.p {
    text-align: center;
    position: absolute;
    height: 35.89%;
    width: 100%;
    top:-102%;
    right: 4%;
    letter-spacing: 0.24px;
    font-weight: 800;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.p1 {
    text-align: center;
    position: absolute;
    height: 15.89%;
    width: 82.13%;
    top: 55.83%;
    right: 12.8%;
    bottom: 64.29%;
    letter-spacing: 0.24px;
    font-weight: 800;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.txt-span {
    line-break: anywhere;
}

.div1,
.div2,
.div3 {
    position: absolute;
    height: 15.89%;
    width: 82.13%;
    top: 19.83%;
    right: 8.8%;
    bottom: 64.29%;
    left: 9.07%;
    letter-spacing: 0.24px;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.div3 {
    height: 5.3%;
    top: 80.17%;
    bottom: 14.53%;
    justify-content: center;
}
.div2_2 {
    bottom: 14.53%;
    justify-content: center;
    height: 5.3%;
    top: 80.17%;
    width: 100%;
    font-size: 27.5px;
    padding-top: 80vh;
    font-weight: 800;
}
.div3 {
    height: 6.16%;
    width: 73.07%;
    top: 72.91%;
    right: 13.33%;
    bottom: 20.94%;
    left: 13.6%;
    font-size: 27px;
    font-weight: 500;
}

.polygon-icon {
    margin: auto;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 19px;
    width: 35vh;
    height: 35vh;
}

.polygon-icon1 {
    top: 50%;
    right: 30vw;
    position: absolute;
    margin: auto;
    width: 117.59px;
    height: 117.59px;
    opacity: 0.4;
}

.rectangle-div,
.rectangle-div1 {
    align-self:center;
    position: absolute;
    top: 56%;
    left:49.4%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    background-color: #ffeaea;
    width: 60.16px;
    height: 12px;
    /*transform: rotate(45deg);*/
    transform-origin: 0 0;
}

.vector-icon {
    position: absolute;
    height: 2.53%;
    width: 5.43%;
    top: 13.56%;
    right: 48.96%;
    bottom: 83.91%;
    left: 45.6%;
    max-width: 100%;
    max-height: 100%;
}

.divBack {
    position: relative;
    border-radius: 40px;
    background: linear-gradient(180deg, #ff5775, #ff9ca7);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-family: Geometria;
}
