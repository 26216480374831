.frame-icon_1,
.help-circle-icon_1 {
    position: absolute;
    overflow: hidden;
}

.frame-icon_1 {
    height: 100vh;
    width: 100%;
    top: 0;
    right: -229.6%;
    bottom: -9.98%;
    left: 0;
    max-width: 100%;
    max-height: 100%;
}

.help-circle-icon_1 {
    top: 4.5vh;
    right:10%;
    width: 3vh;
    height: 3vh;
}

.b_1,
.div1_1 {
    position: absolute;
    letter-spacing: 0.24px;
}

.b_1 {
    height: 3.45%;
    width: 100%;
    top: 4vh;
    bottom: 87.44%;
    display: inline-block;
    text-align: center;
}

.div1_1 {
    height: 4.43%;
    width: 86.67%;
    top: 30vh;
    right: 6.67%;
    bottom: 55.67%;
    left: 6.67%;
    font-size: 5.1vh;
    line-height: 100%;
    text-transform: uppercase;
    text-align:center;
}

.div1_1,
.span_1 {
    font-weight: 800;
}
.p_1 {
    margin-block-start: 0;
    margin-block-end: 3px;
}

.li_1 {
    margin-bottom: 0;
}

.ul_1 {
    margin: 0;
    font-size: 2.1vh;
    padding-left: 19px;
}

.div2_1,
.div3_1 {
    letter-spacing: 0.24px;
    display: inline-block;
    width: 325px;
    margin-right: 5%;
}

.div2_1 {
    position: relative;
    
}

.div3_1 {
    white-space: pre-wrap;
}

.b1_1,
.div3_1,
.group-icon_1 {
    position: relative;
}

.b1_1 {
    letter-spacing: 0.24px;
    line-height: 120%;
    display: inline-block;
}

.group-icon_1 {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.frame-div_1,
.frame-div1_1 {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    margin-right:5%;
}

.frame-div1_1 {
    flex-direction: row;
    gap: 15px;
    text-align: center;
    font-size: 2.6vh;
    color: #ffde52;
}

.frame-div_1 {
    position: absolute;
    top: 36vh;
    left: 25px;
    height: 100vh;
    width:100%;
    overflow: hidden;
    flex-direction: column;
    gap: 20px;
    font-size: 2.1vh;
}

.b2_1,
.b3_1 {
    position: relative;
    letter-spacing: 0.24px;
    line-height: 100%;
    display: inline-block;
}

.b2_1 {
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.b3_1 {
    font-size: 2.1vh;
}

.frame-div2_1,
.frame-div3_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.frame-div2_1 {
    width:100%;
    position: absolute;
    top: 10vh;
    flex-direction: row;
    gap: 5px;
    font-size: 10vh;
    text-align:center;
}

.group-icon1_1 {
    position: absolute;
    height: 8.31%;
    width: 26.49%;
    top: 23vh;
    right: 37.24%;
    bottom: 65.21%;
    left: 36.27%;
    max-width: 100%;
    max-height: 100%;
}

.div_1 {
    position: relative;
    border-radius: 40px;
    background: linear-gradient(180deg, #6967de, #836ef7);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: left;
    font-size: 2.6vh;
    color: #fff;
    font-family: Geometria;
}

@media screen and (max-width: 1200px) {
    .div_1 {
        cursor: auto;
    }
}
