.frame-icon5 {
    position: absolute;
    height: 109.98%;
    width: 329.6%;
    top: 0;
    right: -229.6%;
    bottom: -9.98%;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.p4 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.p5 {
    margin: 0;
}

.txt-span2 {
    line-break: anywhere;
    width: 100%;
}

.div11 {
    position: absolute;
    height: 15.89%;
    width: 82.13%;
    top: 19.83%;
    right: 8.8%;
    bottom: 64.29%;
    left: 9.07%;
    letter-spacing: 0.24px;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.group-icon1 {
    position: absolute;
    margin: auto;
    left: 51%;
    top: 58%;
    transform: translate(-55%, -58%);
    width: 30vh;
    height: 30vh;
}

.group-icon2 {
    position: absolute;
    height: 16.89%;
    width: 32.67%;
    top: 46.65%;
    right: 33.07%;
    bottom: 136.46%;
    left: 34.26%;
    max-width: 100%;
    max-height: 100%;
}

.div12 {
    position: absolute;
    height: 5.3%;
    width: 82.13%;
    top: 74.88%;
    right: 8.8%;
    bottom: 19.83%;
    left: 9.07%;
    letter-spacing: 0.24px;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}

.group-icon3,
.vector-icon10 {
    position: absolute;
    height: 6.45%;
    width: 21.78%;
    top: 82.14%;
    right: 39.28%;
    bottom: 11.41%;
    left: 38.93%;
    max-width: 100%;
    max-height: 100%;
}

.vector-icon10 {
    height: 3.66%;
    width: 8.08%;
    top: 5.83%;
    right: 45.64%;
    bottom: 84.51%;
    left: 46.28%;
}

.div10 {
    position: relative;
    border-radius: 40px;
    background: linear-gradient(180deg, #00ac4f, #00cd90, #009568);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-family: Geometria;
}
