.frame-icon1_2 {
    position: absolute;
    height: 109.98%;
    width: 329.6%;
    top: 0;
    right: -229.6%;
    bottom: -9.98%;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.div5_2 {
    position: absolute;
    height: 15.89%;
    width: 82.13%;
    top: 21.31%;
    right: 7.2%;
    bottom: 62.81%;
    left: 10.67%;
    letter-spacing: 0.24px;
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}
.component2 {
    margin: auto;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 19px;
    width: 35vh;
    height: 35vh;
}
.vector-icon1_2 {
    position: absolute;
    height: 2.53%;
    width: 5.45%;
    top: 15.52%;
    right: 48.08%;
    bottom: 81.95%;
    left: 46.47%;
    max-width: 100%;
    max-height: 100%;
}

.div4_2 {
    position: relative;
    border-radius: 40px;
    background: linear-gradient(180deg, #6967de, #a595ff);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-family: Geometria;
}
