.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}
.group1 {
    position: absolute; 
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 78vh;
}
.group2 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 78vh;
}
.group3 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 78vh;
}
.home-frame11 {
    width: 99vw;
    height: 99.99999vh;
    display: flex;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 40px;
    background-image: linear-gradient(180deg, rgba(208, 207, 255, 1) 0%, rgba(171, 156, 255, 1) 100%);
}

.home-text {
    top:95%;
    width:100%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: center;
    font-style: Bold;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    text-align:center;
}

.home-text02 {
    top: 80%;
    color: rgba(255, 255, 255, 1);
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: stretch;
    font-style: Medium;
    text-align: center;
    font-family: Geometria;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text04 {
    width: 100%;
    text-align: center;
    top: 20%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Medium;
    text-align: center;
    font-family: Geometria;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text06 {
    width: 100%;
    text-align: center;
    top: 112px;
    color: rgba(78, 76, 194, 1);
    height: auto;
    position: absolute;
    font-size: 16px;
    align-self: stretch;
    font-style: Bold;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text08 {
    width: 100%;
    text-align: center;
    top: 77px;
    color: rgba(77, 75, 194, 1);
    height: auto;
    position: absolute;
    font-size: 23px;
    align-self: stretch;
    font-style: ExtraBold;
    font-family: Geometria;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-rectangle4206 {
    top: 234px;
    width: 234px;
    height: 364px;
    opacity: 0.20;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    border-radius: 24px;
    margin-bottom: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-rectangle4209 {
    left: 93px;
    width: 189px;
    height: 40px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 10px;
    margin-bottom: 0;
    top:55%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-rectangle4210 {
    left: 93px;
    width: 189px;
    height: 40px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 10px;
    margin-bottom: 0;
    top: 62%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-rectangle4211 {
    left: 93px;
    width: 189px;
    height: 40px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 10px;
    margin-bottom: 0;
    top: 69%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame1948754412 {
    top: 286px;
    left: 96px;
    width: 186px;
    display: flex;
    opacity: 0.90;
    position: absolute;
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame1948754411 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 5px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;

}

.home-rectangle421001 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 3px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421002 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-rectangle4213 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421003 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-vector {
    width: 6px;
    height: 18px;
    position: relative;
    box-sizing: border-box;
    margin-right: 5px;
    margin-bottom: 0;
}

.home-frame1948754410 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 5px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle4214 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 3px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421004 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-rectangle4215 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421005 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-vector1 {
    width: 7px;
    height: 20px;
    position: relative;
    box-sizing: border-box;
    margin-right: 5px;
    margin-bottom: 0;
}

.home-frame1948754409 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle4216 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 3px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421006 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-rectangle4217 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421007 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-frame1948754413 {
    top: 579px;
    left: 181px;
    width: 14px;
    height: 49px;
    position: absolute;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;
    top: 76%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame1948754414 {
    top: 26%;
    left: 195px;
    width: 14px;
    height: 58px;
    position: absolute;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-text10 {
    top: 45%;
    width: 100%;
    left: -20%;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text12 {
    top: 30%;
    width: 100%;
    left:-20%;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-frame1948754416 {
    top: 99%;
    left: 154px;
    width: 67px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-ellipse282 {
    width: 9px;
    height: 9px;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 20px;
    margin-bottom: 0;
}

.home-ellipse283 {
    width: 9px;
    height: 9px;
    opacity: 0.30;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 20px;
    margin-bottom: 0;
}

.home-ellipse284 {
    width: 9px;
    height: 9px;
    opacity: 0.30;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
}

.home-frame12 {
    width: 99vw;
    height: 99.99999vh;
    width: 100%;
    text-align: center;
    display: flex;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 40px;
    background-image: linear-gradient(180deg, rgba(208, 207, 255, 1) 0%, rgba(171, 156, 255, 1) 100%);
}

.home-text14 {
    top: 85%;
    color: rgba(255, 255, 255, 1);
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: stretch;
    font-style: Medium;
    text-align: center;
    font-family: Geometria;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text16 {
    top: 22%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 14px;
    align-self: auto;
    font-style: Medium;
    text-align: center;
    font-family: Geometria;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text18 {
    top: 112px;
    color: rgba(78, 76, 194, 1);
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    font-size: 16px;
    align-self: stretch;
    font-style: Bold;
    text-align: center;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text20 {
    top: 77px;
    color: rgba(77, 75, 194, 1);
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    font-size: 23px;
    align-self: stretch;
    font-style: ExtraBold;
    text-align: center;
    font-family: Geometria;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-rectangle42061 {
    top: 234px;
    width: 234px;
    height: 364px;
    opacity: 0.20;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    border-radius: 24px;
    margin-bottom: 0;
    top: 55%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame19487544131 {
    width: 14px;
    height: 49px;
    position: absolute;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;
    top: 80%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame1948754415 {
    left: 94px;
    width: 184px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: column;
    top: 66%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-rectangle42091 {
    width: 184px;
    height: 30px;
    position: relative;
    box-sizing: border-box;
    border-color: rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 2px;
    margin-right: 0;
    border-radius: 40px;
    margin-bottom: 8px;
}

.home-group1948754306 {
    width: 184px;
    height: 30px;
    display: flex;
    padding: 0;
    position: relative;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 8px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
}

.home-subtract {
    top: 0px;
    left: 0px;
    width: 184px;
    height: 30px;
    position: absolute;
    box-sizing: border-box;
}

.home-vector1 {
    top: 9px;
    left: 10px;
    width: 14px;
    height: 9px;
    position: absolute;
    box-sizing: border-box;
}

.home-rectangle42111 {
    width: 184px;
    height: 30px;
    position: relative;
    box-sizing: border-box;
    border-color: rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 2px;
    margin-right: 0;
    border-radius: 40px;
    margin-bottom: 8px;
}

.home-rectangle4212 {
    width: 184px;
    height: 30px;
    position: relative;
    box-sizing: border-box;
    border-color: rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 2px;
    margin-right: 0;
    border-radius: 40px;
    margin-bottom: 0;
}

.home-frame19487544121 {
    top: 38%;
    left: 96px;
    width: 91px;
    display: flex;
    opacity: 0.90;
    position: absolute;
    box-sizing: border-box;
    align-items: center;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    left: 40%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame19487544111 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 5px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421008 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421009 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-vector2 {
    width: 6px;
    height: 18px;
    position: relative;
    box-sizing: border-box;
    margin-right: 5px;
    margin-bottom: 0;
}

.home-frame19487544101 {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;

}

.home-rectangle42141 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 3px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421010 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-rectangle42151 {
    width: 24px;
    height: 41px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
}

.home-rectangle421011 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 41px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 7px;
    margin-bottom: 0;
}

.home-rectangle4208 {
    top: 48%;
    left: 94px;
    width: 186px;
    height: 7px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 9px;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-rectangle42092 {
    top: 50%;
    left: 94px;
    width: 138px;
    height: 7px;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 9px;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame19487544141 {
    top: 30%;
    left: 181px;
    width: 14px;
    height: 63px;
    position: absolute;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-text22 {
    top: 44%;
    left: -20%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    width: 100%;
    text-align: center;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text24 {
    top: 51%;
    left: -10%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    width: 100%;
    text-align: center;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text26 {
    top: 32%;
    left: -20%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: auto;
    font-style: Bold;
    width: 100%;
    text-align: center;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}
.home-text27 {
    top: 95%;
    width: 100%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: center;
    font-style: Bold;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    text-align: center;
}

.home-frame19487544161 {
    top: 99%;
    left: 154px;
    width: 67px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-ellipse2831 {
    width: 9px;
    height: 9px;
    opacity: 0.30;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 20px;
    margin-bottom: 0;
}

.home-ellipse2821 {
    width: 9px;
    height: 9px;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 20px;
    margin-bottom: 0;
}

.home-ellipse2841 {
    width: 9px;
    height: 9px;
    opacity: 0.30;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
}

.home-frame13 {
    width: 99vw;
    height: 99.99999vh;
    display: flex;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 40px;
    background-image: linear-gradient(180deg, rgba(208, 207, 255, 1) 0%, rgba(171, 156, 255, 1) 100%);
}

.home-text28 {
    top: 112px;
    color: rgba(78, 76, 194, 1);
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    font-size: 16px;
    align-self: stretch;
    font-style: Bold;
    text-align: center;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-text30 {
    top: 77px;
    color: rgba(77, 75, 194, 1);
    width: 100%;
    text-align: center;
    height: auto;
    position: absolute;
    font-size: 23px;
    align-self: stretch;
    font-style: ExtraBold;
    text-align: center;
    font-family: Geometria;
    font-weight: 800;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.home-rectangle42062 {
    top: 50%;
    left: 71px;
    width: 234px;
    height: 364px;
    opacity: 0.20;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    border-radius: 24px;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-group1948754301 {
    top: 361px;
    left: 145px;
    width: 85.4742660522461px;
    height: 96.98040008544922px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
    top:50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-frame {
    top: 0px;
    left: 0px;
    width: 85.4742660522461px;
    height: 96.98040008544922px;
    display: flex;
    padding: 0;
    position: absolute;
    align-self: auto;
    box-sizing: border-box;
    align-items: flex-start;
    flex-shrink: 1;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    border-radius: 0px 0px 0px 0px;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;

}

.home-vector3 {
    top: 0px;
    left: 0px;
    width: 85px;
    height: 97px;
    position: absolute;
    box-sizing: border-box;

}

.home-frame19487544162 {
    top: 99%;
    left: 154px;
    width: 67px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    align-items: flex-start;
    border-color: transparent;
    margin-right: 0;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-ellipse2832 {
    width: 9px;
    height: 9px;
    opacity: 0.30;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 20px;
    margin-bottom: 0;
}

.home-ellipse2842 {
    width: 9px;
    height: 9px;
    opacity: 0.30;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 20px;
    margin-bottom: 0;
}

.home-ellipse2822 {
    width: 9px;
    height: 9px;
    position: relative;
    box-sizing: border-box;
    border-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
}

.home-polygon3 {
    top: 80%;
    left: 270px;
    width: 63px;
    height: 63px;
    opacity: 0.70;
    position: absolute;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;
    left: 55%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.home-polygon4 {
    top: 80%;
    left: 217px;
    width: 63px;
    height: 63px;
    opacity: 0.70;
    position: absolute;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;
    left: 67%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.home-text29 {
    top: 95%;
    width: 100%;
    color: rgba(255, 255, 255, 1);
    height: auto;
    position: absolute;
    font-size: 14px;
    align-self: center;
    font-style: Bold;
    font-family: Geometria;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
    text-align: center;
}
